import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import ArrowRightIcon from "../Icons/ArrowRightIcon"
import Element from "../UI/Element"
import ScaledImage from "../UI/ScaledImage"

const ProductEntry = ({ to, name, productType, img, first, code }) => {
  return (
    <Product to={to}>
      <ScaledImage scaleHeight={first ? 469 : 9} scaleWidth={first ? 1105 : 16}>
        <img src={img} alt={name} />
      </ScaledImage>
      <ProductContent>
        {productType && <ProductType>{productType}</ProductType>}
        {code && <Element fontWeight="bold">{code}</Element>}
        <Element as="span" fontWeight="bold" mr={4}>
          {name}
        </Element>
        <ArrowRightIcon />
      </ProductContent>
    </Product>
  )
}

const ProductContent = styled.div`
  padding: 24px;
`

const ProductType = styled.div`
  text-transform: uppercase;
  font-size: 12px;
`

const Product = styled(Link)`
  display: block;
  background: var(--brand);
  color: #fff;
  position: relative;
  transition: var(--transition);

  &:after {
    content: "";
    position: absolute;
    bottom: -4px;
    right: -4px;
    height: 100%;
    width: 100%;
    z-index: -1;
    transition: var(--transition);
  }

  &:hover {
    color: var(--brand);
    background: #fff;

    &:after {
      background: var(--brand);
    }
  }
`

export default ProductEntry
