import React, { useState, useEffect } from "react"
import axios from "axios"
import { navigate } from "gatsby"
import Site from "../../components/Layout/Site"
import { Col, Container, Row } from "../../components/UI/Grid"
import { Heading, Paragraph } from "../../components/UI/Typography"
import { Section } from "../../components/UI/Section"
import HeavyLifting from "../../components/Shared/HeavyLifting"
import ProductEntry from "../../components/Shared/ProductEntry"
const Subcategories = ({ location, data }) => {
  const [processing, setProcessing] = useState(true)
  const [subcategory, setSubcategory] = useState(null)
  const isBrowser = typeof window !== "undefined"

  useEffect(() => {
    axios
      .get(
        `${
          isBrowser &&
          window?.location?.origin === "https://boeingmhc.digiteer.dev"
            ? process.env.GATSBY_STAGING_API_URL
            : process.env.GATSBY_API_URL
        }/${location.pathname}`
      )
      .then((res) => {
        if (res.data) {
          setSubcategory(res.data)
        } else {
          navigate("/404")
        }
      })
      .finally(() => setProcessing(false))
  }, [location.pathname])

  if (processing) {
    return null
  }

  return (
    <Site>
      <Section>
        <Container>
          <Row textAlign="center" justifyContent="center">
            <Col col={{ lg: 6 }}>
              <Heading fs="h2">{subcategory.name}</Heading>
              <Paragraph mt={6}>{subcategory.description}</Paragraph>
            </Col>
          </Row>

          <Row mt={10}>
            {subcategory.products.map((p, idx) => (
              <Col col={{ lg: idx === 0 ? 12 : 6 }} key={p.id} mb={6}>
                <ProductEntry
                  to={`/products/${p.slug}`}
                  name={p.name}
                  img={p.thumbnail}
                  productType={subcategory.product_type}
                  first={idx === 0}
                  code={p.code}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </Section>
      <HeavyLifting />
    </Site>
  )
}

export default Subcategories
